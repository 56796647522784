<template>
  <div class="wrapper-404 outside">
    <div id="box">
      <div id="logo">
        <img src="../../assets/images/logo-klaus.svg">
      </div>
      <div class="content-box">
        <h1>Ops... 404!</h1>
        <h2>Parece que você não conseguiu encontrar o que procurava. </h2>
        <router-link to="/">
          Voltar ao ínicio.
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/views-nao-autenticadas.css'
import "@/components/PaginaNaoEncontrada/404.css";

export default {
  name: "paginaNaoEncontrada",
  data() {
    return {}
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
